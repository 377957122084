.nsw-steps {

    &__item {

        &--counter {

            &:after {
                visibility: visible;
                content: attr(data-circle-value);
            }
        }
    }
}
