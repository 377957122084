$assets-path-base-dir: '/_resources/themes/nswdds/dist/';

@import '../../node_modules/nsw-design-system/src/main';
@import 'Tools/assetspath';
@import 'Tools/clearfix';
@import 'Overrides/main-nav';
@import 'Overrides/side-nav';
@import 'Overrides/embed-object';
@import 'Overrides/ElementEmbed';
@import 'Overrides/general';
@import 'Overrides/nsw-media';
@import 'Overrides/nsw-link-list';
@import 'Overrides/nsw-in-page-alert';
@import 'Overrides/nsw-progress-indicator';
@import 'Overrides/nsw-steps';
@import 'Overrides/highcharts';
@import 'Overrides/print';

@import 'Components/CapabilityFieldGroup';
@import 'Components/CapabilityGroupsField';
@import 'Components/CapabilityRatingPill';
@import 'Components/CapabilityVis';
@import 'Components/CATResults';
@import 'Components/SurveyOptionsetField';

@import 'Scoped/cmsContent';

//@import 'Legacy/ChartEmbed';
@import 'Legacy/Highcharts';

//@import '../../node_modules/highcharts-11/css/highcharts.css';

.nsw-bg--brand-dark,.nsw-bg--brand-dark-hover {
    background-color: rgba(var(--nsw-brand-dark-rgb),var(--nsw-bg-opacity))!important;
}
.nsw-bg--brand-dark-hover:hover {--nsw-bg-opacity: 1;}

.nsw-bg--brand-light,.nsw-bg--brand-light-hover {
    background-color: rgba(var(--nsw-brand-light-rgb),var(--nsw-bg-opacity))!important;
}
.nsw-bg--brand-light-hover:hover {--nsw-bg-opacity: 1;}

.nsw-bg--brand-supplementary,.nsw-bg--brand-supplementary-hover {
    background-color: rgba(var(--nsw-brand-supplementary-rgb),var(--nsw-bg-opacity))!important;
}
.nsw-bg--brand-supplementary-hover:hover {--nsw-bg-opacity: 1;}

.nsw-bg--brand-accent,.nsw-bg--brand-accent-hover {
    background-color: rgba(var(--nsw-brand-accent-rgb),var(--nsw-bg-opacity))!important;
}
.nsw-bg--brand-accent-hover:hover {--nsw-bg-opacity: 1;}

.-theme-dark-grey_01 {--nsw-brand-dark: var(--nsw-palette-grey-01); --nsw-brand-dark-rgb: var(--nsw-palette-grey-01-rgb);}
.-theme-dark-green_01 {--nsw-brand-dark: var(--nsw-palette-green-01); --nsw-brand-dark-rgb: var(--nsw-palette-green-01-rgb);}
.-theme-dark-teal_01 {--nsw-brand-dark: var(--nsw-palette-teal-01); --nsw-brand-dark-rgb: var(--nsw-palette-teal-01-rgb);}
.-theme-dark-blue_01 {--nsw-brand-dark: var(--nsw-palette-blue-01); --nsw-brand-dark-rgb: var(--nsw-palette-blue-01-rgb);}
.-theme-dark-purple_01 {--nsw-brand-dark: var(--nsw-palette-purple-01); --nsw-brand-dark-rgb: var(--nsw-palette-purple-01-rgb);}
.-theme-dark-fuchsia_01 {--nsw-brand-dark: var(--nsw-palette-fuchsia-01); --nsw-brand-dark-rgb: var(--nsw-palette-fuchsia-01-rgb);}
.-theme-dark-red_01 {--nsw-brand-dark: var(--nsw-palette-red-01); --nsw-brand-dark-rgb: var(--nsw-palette-red-01-rgb);}
.-theme-dark-orange_01 {--nsw-brand-dark: var(--nsw-palette-orange-01); --nsw-brand-dark-rgb: var(--nsw-palette-orange-01-rgb);}
.-theme-dark-yellow_01 {--nsw-brand-dark: var(--nsw-palette-yellow-01); --nsw-brand-dark-rgb: var(--nsw-palette-yellow-01-rgb);}
.-theme-dark-brown_01 {--nsw-brand-dark: var(--nsw-palette-brown-01); --nsw-brand-dark-rgb: var(--nsw-palette-brown-01-rgb);}

.-theme-light-grey_03 {--nsw-brand-light: var(--nsw-palette-grey-03); --nsw-brand-light-rgb: var(--nsw-palette-grey-03-rgb);}
.-theme-light-grey_04 {--nsw-brand-light: var(--nsw-palette-grey-04); --nsw-brand-light-rgb: var(--nsw-palette-grey-04-rgb);}
.-theme-light-green_02 {--nsw-brand-light: var(--nsw-palette-green-02); --nsw-brand-light-rgb: var(--nsw-palette-green-02-rgb);}
.-theme-light-green_03 {--nsw-brand-light: var(--nsw-palette-green-03); --nsw-brand-light-rgb: var(--nsw-palette-green-03-rgb);}
.-theme-light-green_04 {--nsw-brand-light: var(--nsw-palette-green-04); --nsw-brand-light-rgb: var(--nsw-palette-green-04-rgb);}
.-theme-light-teal_03 {--nsw-brand-light: var(--nsw-palette-teal-03); --nsw-brand-light-rgb: var(--nsw-palette-teal-03-rgb);}
.-theme-light-teal_04 {--nsw-brand-light: var(--nsw-palette-teal-04); --nsw-brand-light-rgb: var(--nsw-palette-teal-04-rgb);}
.-theme-light-blue_03 {--nsw-brand-light: var(--nsw-palette-blue-03); --nsw-brand-light-rgb: var(--nsw-palette-blue-03-rgb);}
.-theme-light-blue_04 {--nsw-brand-light: var(--nsw-palette-blue-04); --nsw-brand-light-rgb: var(--nsw-palette-blue-04-rgb);}
.-theme-light-purple_03 {--nsw-brand-light: var(--nsw-palette-purple-03); --nsw-brand-light-rgb: var(--nsw-palette-purple-03-rgb);}
.-theme-light-purple_04 {--nsw-brand-light: var(--nsw-palette-purple-04); --nsw-brand-light-rgb: var(--nsw-palette-purple-04-rgb);}
.-theme-light-fuchsia_03 {--nsw-brand-light: var(--nsw-palette-fuchsia-03); --nsw-brand-light-rgb: var(--nsw-palette-fuchsia-03-rgb);}
.-theme-light-fuchsia_04 {--nsw-brand-light: var(--nsw-palette-fuchsia-04); --nsw-brand-light-rgb: var(--nsw-palette-fuchsia-04-rgb);}
.-theme-light-red_03 {--nsw-brand-light: var(--nsw-palette-red-03); --nsw-brand-light-rgb: var(--nsw-palette-red-03-rgb);}
.-theme-light-red_04 {--nsw-brand-light: var(--nsw-palette-red-04); --nsw-brand-light-rgb: var(--nsw-palette-red-04-rgb);}
.-theme-light-orange_02 {--nsw-brand-light: var(--nsw-palette-orange-02); --nsw-brand-light-rgb: var(--nsw-palette-orange-02-rgb);}
.-theme-light-orange_03 {--nsw-brand-light: var(--nsw-palette-orange-03); --nsw-brand-light-rgb: var(--nsw-palette-orange-03-rgb);}
.-theme-light-orange_04 {--nsw-brand-light: var(--nsw-palette-orange-04); --nsw-brand-light-rgb: var(--nsw-palette-orange-04-rgb);}
.-theme-light-yellow_02 {--nsw-brand-light: var(--nsw-palette-yellow-02); --nsw-brand-light-rgb: var(--nsw-palette-yellow-02-rgb);}
.-theme-light-yellow_03 {--nsw-brand-light: var(--nsw-palette-yellow-03); --nsw-brand-light-rgb: var(--nsw-palette-yellow-03-rgb);}
.-theme-light-yellow_04 {--nsw-brand-light: var(--nsw-palette-yellow-04); --nsw-brand-light-rgb: var(--nsw-palette-yellow-04-rgb);}
.-theme-light-brown_02 {--nsw-brand-light: var(--nsw-palette-brown-02); --nsw-brand-light-rgb: var(--nsw-palette-brown-02-rgb);}
.-theme-light-brown_03 {--nsw-brand-light: var(--nsw-palette-brown-03); --nsw-brand-light-rgb: var(--nsw-palette-brown-03-rgb);}
.-theme-light-brown_04 {--nsw-brand-light: var(--nsw-palette-brown-04); --nsw-brand-light-rgb: var(--nsw-palette-brown-04-rgb);}

.-theme-supplementary-grey_01 {--nsw-brand-supplementary: var(--nsw-palette-grey-01); --nsw-brand-supplementary-rgb: var(--nsw-palette-grey-01-rgb);}
.-theme-supplementary-grey_02 {--nsw-brand-supplementary: var(--nsw-palette-grey-02); --nsw-brand-supplementary-rgb: var(--nsw-palette-grey-02-rgb);}
.-theme-supplementary-green_01 {--nsw-brand-supplementary: var(--nsw-palette-green-01); --nsw-brand-supplementary-rgb: var(--nsw-palette-green-01-rgb);}
.-theme-supplementary-teal_01 {--nsw-brand-supplementary: var(--nsw-palette-teal-01); --nsw-brand-supplementary-rgb: var(--nsw-palette-teal-01-rgb);}
.-theme-supplementary-teal_02 {--nsw-brand-supplementary: var(--nsw-palette-teal-02); --nsw-brand-supplementary-rgb: var(--nsw-palette-teal-02-rgb);}
.-theme-supplementary-blue_01 {--nsw-brand-supplementary: var(--nsw-palette-blue-01); --nsw-brand-supplementary-rgb: var(--nsw-palette-blue-01-rgb);}
.-theme-supplementary-blue_02 {--nsw-brand-supplementary: var(--nsw-palette-blue-02); --nsw-brand-supplementary-rgb: var(--nsw-palette-blue-02-rgb);}
.-theme-supplementary-purple_01 {--nsw-brand-supplementary: var(--nsw-palette-purple-01); --nsw-brand-supplementary-rgb: var(--nsw-palette-purple-01-rgb);}
.-theme-supplementary-purple_02 {--nsw-brand-supplementary: var(--nsw-palette-purple-02); --nsw-brand-supplementary-rgb: var(--nsw-palette-purple-02-rgb);}
.-theme-supplementary-fuchsia_01 {--nsw-brand-supplementary: var(--nsw-palette-fuchsia-01); --nsw-brand-supplementary-rgb: var(--nsw-palette-fuchsia-01-rgb);}
.-theme-supplementary-fuchsia_02 {--nsw-brand-supplementary: var(--nsw-palette-fuchsia-02); --nsw-brand-supplementary-rgb: var(--nsw-palette-fuchsia-02-rgb);}
.-theme-supplementary-red_01 {--nsw-brand-supplementary: var(--nsw-palette-red-01); --nsw-brand-supplementary-rgb: var(--nsw-palette-red-01-rgb);}
.-theme-supplementary-red_02 {--nsw-brand-supplementary: var(--nsw-palette-red-02); --nsw-brand-supplementary-rgb: var(--nsw-palette-red-02-rgb);}
.-theme-supplementary-orange_01 {--nsw-brand-supplementary: var(--nsw-palette-orange-01); --nsw-brand-supplementary-rgb: var(--nsw-palette-orange-01-rgb);}
.-theme-supplementary-yellow_01 {--nsw-brand-supplementary: var(--nsw-palette-yellow-01); --nsw-brand-supplementary-rgb: var(--nsw-palette-yellow-01-rgb);}
.-theme-supplementary-brown_01 {--nsw-brand-supplementary: var(--nsw-palette-brown-01); --nsw-brand-supplementary-rgb: var(--nsw-palette-brown-01-rgb);}

.-theme-accent-grey_01 {--nsw-brand-accent: var(--nsw-palette-grey-01); --nsw-brand-accent-rgb: var(--nsw-palette-grey-01-rgb);}
.-theme-accent-grey_02 {--nsw-brand-accent: var(--nsw-palette-grey-02); --nsw-brand-accent-rgb: var(--nsw-palette-grey-02-rgb);}
.-theme-accent-grey_03 {--nsw-brand-accent: var(--nsw-palette-grey-03); --nsw-brand-accent-rgb: var(--nsw-palette-grey-03-rgb);}
.-theme-accent-grey_04 {--nsw-brand-accent: var(--nsw-palette-grey-04); --nsw-brand-accent-rgb: var(--nsw-palette-grey-04-rgb);}
.-theme-accent-green_01 {--nsw-brand-accent: var(--nsw-palette-green-01); --nsw-brand-accent-rgb: var(--nsw-palette-green-01-rgb);}
.-theme-accent-green_02 {--nsw-brand-accent: var(--nsw-palette-green-02); --nsw-brand-accent-rgb: var(--nsw-palette-green-02-rgb);}
.-theme-accent-green_03 {--nsw-brand-accent: var(--nsw-palette-green-03); --nsw-brand-accent-rgb: var(--nsw-palette-green-03-rgb);}
.-theme-accent-green_04 {--nsw-brand-accent: var(--nsw-palette-green-04); --nsw-brand-accent-rgb: var(--nsw-palette-green-04-rgb);}
.-theme-accent-teal_01 {--nsw-brand-accent: var(--nsw-palette-teal-01); --nsw-brand-accent-rgb: var(--nsw-palette-teal-01-rgb);}
.-theme-accent-teal_02 {--nsw-brand-accent: var(--nsw-palette-teal-02); --nsw-brand-accent-rgb: var(--nsw-palette-teal-02-rgb);}
.-theme-accent-teal_03 {--nsw-brand-accent: var(--nsw-palette-teal-03); --nsw-brand-accent-rgb: var(--nsw-palette-teal-03-rgb);}
.-theme-accent-teal_04 {--nsw-brand-accent: var(--nsw-palette-teal-04); --nsw-brand-accent-rgb: var(--nsw-palette-teal-04-rgb);}
.-theme-accent-blue_01 {--nsw-brand-accent: var(--nsw-palette-blue-01); --nsw-brand-accent-rgb: var(--nsw-palette-blue-01-rgb);}
.-theme-accent-blue_02 {--nsw-brand-accent: var(--nsw-palette-blue-02); --nsw-brand-accent-rgb: var(--nsw-palette-blue-02-rgb);}
.-theme-accent-blue_03 {--nsw-brand-accent: var(--nsw-palette-blue-03); --nsw-brand-accent-rgb: var(--nsw-palette-blue-03-rgb);}
.-theme-accent-blue_04 {--nsw-brand-accent: var(--nsw-palette-blue-04); --nsw-brand-accent-rgb: var(--nsw-palette-blue-04-rgb);}
.-theme-accent-purple_01 {--nsw-brand-accent: var(--nsw-palette-purple-01); --nsw-brand-accent-rgb: var(--nsw-palette-purple-01-rgb);}
.-theme-accent-purple_02 {--nsw-brand-accent: var(--nsw-palette-purple-02); --nsw-brand-accent-rgb: var(--nsw-palette-purple-02-rgb);}
.-theme-accent-purple_03 {--nsw-brand-accent: var(--nsw-palette-purple-03); --nsw-brand-accent-rgb: var(--nsw-palette-purple-03-rgb);}
.-theme-accent-purple_04 {--nsw-brand-accent: var(--nsw-palette-purple-04); --nsw-brand-accent-rgb: var(--nsw-palette-purple-04-rgb);}
.-theme-accent-fuchsia_01 {--nsw-brand-accent: var(--nsw-palette-fuchsia-01); --nsw-brand-accent-rgb: var(--nsw-palette-fuchsia-01-rgb);}
.-theme-accent-fuchsia_02 {--nsw-brand-accent: var(--nsw-palette-fuchsia-02); --nsw-brand-accent-rgb: var(--nsw-palette-fuchsia-02-rgb);}
.-theme-accent-fuchsia_03 {--nsw-brand-accent: var(--nsw-palette-fuchsia-03); --nsw-brand-accent-rgb: var(--nsw-palette-fuchsia-03-rgb);}
.-theme-accent-fuchsia_04 {--nsw-brand-accent: var(--nsw-palette-fuchsia-04); --nsw-brand-accent-rgb: var(--nsw-palette-fuchsia-04-rgb);}
.-theme-accent-red_01 {--nsw-brand-accent: var(--nsw-palette-red-01); --nsw-brand-accent-rgb: var(--nsw-palette-red-01-rgb);}
.-theme-accent-red_02 {--nsw-brand-accent: var(--nsw-palette-red-02); --nsw-brand-accent-rgb: var(--nsw-palette-red-02-rgb);}
.-theme-accent-red_03 {--nsw-brand-accent: var(--nsw-palette-red-04); --nsw-brand-accent-rgb: var(--nsw-palette-red-03-rgb);}
.-theme-accent-red_04 {--nsw-brand-accent: var(--nsw-palette-red-04); --nsw-brand-accent-rgb: var(--nsw-palette-red-04-rgb);}
.-theme-accent-orange_01 {--nsw-brand-accent: var(--nsw-palette-orange-01); --nsw-brand-accent-rgb: var(--nsw-palette-orange-01-rgb);}
.-theme-accent-orange_02 {--nsw-brand-accent: var(--nsw-palette-orange-02); --nsw-brand-accent-rgb: var(--nsw-palette-orange-02-rgb);}
.-theme-accent-orange_03 {--nsw-brand-accent: var(--nsw-palette-orange-03); --nsw-brand-accent-rgb: var(--nsw-palette-orange-03-rgb);}
.-theme-accent-orange_04 {--nsw-brand-accent: var(--nsw-palette-orange-04); --nsw-brand-accent-rgb: var(--nsw-palette-orange-04-rgb);}
.-theme-accent-yellow_01 {--nsw-brand-accent: var(--nsw-palette-yellow-01); --nsw-brand-accent-rgb: var(--nsw-palette-yellow-01-rgb);}
.-theme-accent-yellow_02 {--nsw-brand-accent: var(--nsw-palette-yellow-02); --nsw-brand-accent-rgb: var(--nsw-palette-yellow-02-rgb);}
.-theme-accent-yellow_03 {--nsw-brand-accent: var(--nsw-palette-yellow-03); --nsw-brand-accent-rgb: var(--nsw-palette-yellow-03-rgb);}
.-theme-accent-yellow_04 {--nsw-brand-accent: var(--nsw-palette-yellow-04); --nsw-brand-accent-rgb: var(--nsw-palette-yellow-04-rgb);}
.-theme-accent-brown_01 {--nsw-brand-accent: var(--nsw-palette-brown-01); --nsw-brand-accent-rgb: var(--nsw-palette-brown-01-rgb);}
.-theme-accent-brown_02 {--nsw-brand-accent: var(--nsw-palette-brown-02); --nsw-brand-accent-rgb: var(--nsw-palette-brown-02-rgb);}
.-theme-accent-brown_03 {--nsw-brand-accent: var(--nsw-palette-brown-03); --nsw-brand-accent-rgb: var(--nsw-palette-brown-03-rgb);}
.-theme-accent-brown_04 {--nsw-brand-accent: var(--nsw-palette-brown-04); --nsw-brand-accent-rgb: var(--nsw-palette-brown-04-rgb);}
