.nsw-link-list {

    &--content {

        li {
            position: relative;
            padding: rem(16px);

            display: flex;
            justify-content: space-between;
            align-items: center;

            &:focus-within {
                @include nsw-focus($offset: false);
                outline-offset: -3px;
            }

            &:hover {
                @include nsw-link-hover;
            }

            a {
                @include pseudo-clickable-block;
                position: static;
                display: block;
                padding: 0;
                font-weight: var(--nsw-font-bold);
                color: var(--nsw-brand-dark);

                &:focus {
                    outline: none;
                }

                &:hover {
                    background-color: transparent;
                    outline: none;
                }
            }
        }
    }

    &__body {
        max-width: rem(700px);
    }

    &__description {
        @include font-size('xs');
    }
}
