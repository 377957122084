.nsw-main-nav {
    $root: &;

    z-index: 600;

    &__title,
    &__description {
        max-width: rem(600px);
    }

    &__sub-nav__secondary {
        display: none;
    }

    @include breakpoint($nsw-desktop-breakpoint)
    {
        &__title.is-primary {
            grid-area: navtitle;
            padding: {
                top: rem(48px);
                left: rem(40px);
                right: rem(40px);
            }
        }

        &__description.is-primary {
            grid-area: navdescription;
            padding: {
                left: rem(40px);
                right: rem(40px);
            }
        }

        &__sub-list.is-primary {
            grid-area: navsublist;
            padding: {
                left: rem(40px);
                right: rem(40px);
                bottom: rem(48px);
            }
        }

        &__sub-nav__secondary {
            grid-area: navsecondary;
        }

        &__sub-nav {
            padding: 0;

            &.-secondary-on {
                display: grid;
                grid-template-columns: 2.5fr 1fr;
                grid-gap: 0;
                grid-template-areas:
                    "navtitle navsecondary"
                    "navdescription navsecondary"
                    "navsublist navsecondary"
            }

            //&__primary {
            //    padding: rem(48px) rem(40px);
            //}

            &__secondary {
                display: block;
                padding: rem(48px) rem(36px);
                background-color: var(--nsw-brand-light);

                .nsw-link-list {

                    > ul {
                        margin: 0;
                        padding: 0;
                        display: block;

                        > li {
                            width: 100%;
                            border: {
                                bottom: 0;
                                top: rem(1px) solid var(--nsw-palette-purple-03);
                            }
                            margin: {
                                left: 0;
                                right: 0;
                            }

                            &:last-child {
                                border-bottom: rem(1px) solid var(--nsw-palette-purple-03);
                            }

                            > a {
                                padding: rem(16px) rem(8px);
                                font-size: var(--nsw-font-size-xs);
                                line-height: var(--nsw-font-height-xs);

                                > .nsw-material-icons {
                                    display: inline-block;
                                    margin-right: 0;
                                }
                            }
                        }
                    }
                }
            }
        }


        &__sub-list li#{$root}__sub-item
        {
            position: relative;
            padding: rem(16px);

            &:focus-within {
                @include nsw-focus($offset: false);
                outline-offset: -3px;
            }

            &:hover {
                @include nsw-link-hover;
            }

            a#{$root}__sub-item__link {
                @include pseudo-clickable-block;
                position: static;
                display: block;
                padding: 0;
                font-weight: var(--nsw-font-bold);
                color: var(--nsw-brand-dark);

                &:focus {
                    outline: none;
                }

                &:hover {
                    background-color: transparent;
                    outline: none;
                }
            }
        }
    }
}
