.CATResults {
    border-left: 6px solid currentColor;
}

.CATResults__hd {
    padding-top: 16px;
    padding-bottom: 16px;
    position: sticky;
    top: 0;
}

.CATResults__logo {
    fill: currentColor !important;
}

.CATResults__capability {
    border-bottom: 1px solid currentColor;
}

.CATResults__capability:last-child {
    border-bottom: 0;
}

.pdfexport .cat-actions.nsw-display-flex {
    display: none !important;
}

@media print {
    .cat-actions.nsw-display-flex {
        display: none !important;
    }
    .CATResults__hd {
        position: relative !important;
    }
}
