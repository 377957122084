.nsw-masthead {
    min-height: rem(34px) !important;
}

html:not(.icons-loaded) .material-icons {
    visibility: hidden;
}

html:not(.icons-loaded) .nsw-main-nav__list .material-icons {
    width: rem(24px);
    overflow: hidden;
}

html:not(.icons-loaded) .nsw-breadcrumbs li::before {
    visibility: hidden;
    width: rem(20px);
    overflow: hidden;
}

.nsw-media > svg {
    width: 100%;
    height: auto;
}

.nsw-grid .nsw-callout {height: 100%;}

.nsw-grid .nsw-grid {
    margin: {
        top: rem(-16px);
        bottom: rem(-16px);
    }
}

.nsw-layout__main > .nsw-section:first-child {
    padding-top: 0;
}

span.refinement-count {
    font-size: 14px;
    display: inline !important;
}

.search-result-attr {

    &--size {
        text-transform: uppercase;
    }

    &[data-search-result-type="html"] {
        display: none;
    }
}

.search-result-url {
    overflow-wrap: break-word;
}

.ais-Pagination,
.ais-SearchBox-reset {
    display: none !important;
}

.nsw-header {
    position: relative;
    z-index: 610;

    @media (max-width:991px)
    {
        z-index: 500;

        .nsw-form__predictive-list {
            display: none !important;
        }
    }
}

.nsw-form__predictive {

    .nsw-form__predictive-list {
        display: none;
    }

    &:focus-within {

        .nsw-form__predictive-list {
            display: block;
        }
    }
}

.nsw-form__predictive-list a:visited {
    color: var(--nsw-text-dark);
}

.nsw-back-to-top {
    z-index: 700;
}

.nsw-form__helper {

    &--warning {
        background-color: var(--nsw-status-warning-bg);
        margin-top: rem(8px);
        padding: rem(8px);
        font-weight: var(--nsw-font-bold);
        color: var(--nsw-text-dark);
        background-repeat: no-repeat;
        background-position: left rem(8px) top rem(8px);
        background-size: 1rem auto;
        display: flex;
        align-items: center;

        .nsw-material-icons {
            color: var(--nsw-status-warning);
            font-size: rem(map-get($nsw-icon-sizes, 20));
            margin-right: rem(4px);
        }
    }
}

.nsw-form__required {

    &:has(.nsw-form__required--last-word) {

        &::after {
            content: none;
        }
    }

    &--last-word {
        white-space: nowrap;

        &::after {
            content: " *";
            color: var(--nsw-status-error);
        }
    }
}
