.CapabilityVis {-webkit-print-color-adjust: exact; print-color-adjust: exact;}
.CapabilityVis__grid {display: grid; grid-template-columns: 210px 1fr; align-items: center;}
.CapabilityVis__hd {-webkit-print-color-adjust: exact; print-color-adjust: exact; display: grid; align-items: center; z-index: 50; height: 100%; margin-top: 0; padding: 9px; line-height: 1.3; border-bottom: 2px solid var(--nsw-palette-white); background-color: var(--nsw-palette-grey-04) !important;}
.CapabilityVis__hd:first-child {padding-right: 12px; border-right: 1px solid var(--nsw-palette-white);}
.CapabilityVis__hd em {display: block;}

.CapabilityVis__labels {text-align: center; padding-top: 3px; margin-bottom: -3px; margin-left: -9px; margin-right: -9px; display:grid; grid-template-columns: 1fr 1fr 1fr;}
.CapabilityVis__label {padding-left: 3px; padding-right: 3px;}
.CapabilityVis__label > span {-webkit-print-color-adjust: exact; print-color-adjust: exact; font-size: 12px; display: inline-block; padding: 3px 6px; border-radius: 6px;}

.CapabilityVis__title {margin-top: 0; padding: 9px 12px 9px 9px; font-size: 14px; line-height: 1.3; border-bottom: 1px solid var(--nsw-palette-grey-04);}
.CapabilityVis__bar {position: relative; border-bottom: 1px solid var(--nsw-palette-white); height:100%; min-height: 40px; display:grid; grid-template-columns: 1fr 1fr 1fr;}
.CapabilityVis__rating {-webkit-print-color-adjust: exact; print-color-adjust: exact; position: relative;}
.CapabilityVis__score {z-index: 20; position: absolute; top: 50%; transform: translate(-50%,-50%); font-size: 21px; color:var(--nsw-brand-supplementary) !important;}

@media (max-width:700px) {
    .CapabilityVis__hd {display: none !important;}
    .CapabilityVis__grid {grid-template-columns: 1fr !important; margin-top: -9px;}
    .CapabilityVis__title {border-bottom: 0 !important; padding-bottom: 6px !important; padding-left: 0 !important;}
}
