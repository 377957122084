.s-cmsContent,
.mce-content-body {
    @include clearfix;

    &:not(.-width-max) {
        max-width: rem(800px);
    }

    img {
        display: block;
        height: auto;
        //min-width: 100%;
        max-width: 100%;
        //margin-bottom: $cmscontent-margin-unit;

        //@include mq-from(xs) {
        //    min-width: auto;
        //}
    }

    sup, sub {
        font-size: 0.8em;
    }

    h1, h2, h3, h4 {

        sup, sub {
            font-size: 0.65em;
        }
    }

    .captionImage,
    .embed {
        overflow: hidden;
        height: auto;
        width: auto;
        max-width: 100%;
        //margin-bottom: $cmscontent-margin-unit;
        margin-bottom: rem(16px);

        &:last-child {
            margin-bottom: 0;
        }

        //@include mq-before(xs) {
        //    width: 100% !important; // overriding WYSIWYG inline style
        //}

        //@include mq-from(xs) {
        //    //overflow: auto;
        //    width: auto;
        //}

        //> .caption {
        //    font-size: rem(14);
        //    line-height: vr(1.5);
        //    text-align: center;
        //    margin-top: vr(1);
        //}

        //&.left,
        //&.leftAlone {
        //
        //    > .caption {
        //
        //        @include mq-from(xs) {
        //            text-align: left;
        //        }
        //    }
        //}
        //
        //&.right,
        //&.rightAlone {
        //
        //    > .caption {
        //
        //        @include mq-from(xs) {
        //            text-align: right;
        //        }
        //    }
        //}
        //
        //&.center {
        //
        //    > .caption {
        //
        //        @include mq-from(xs) {
        //            text-align: center;
        //        }
        //    }
        //}

        > picture img,
        > img {
            margin-bottom: 0;
            position: relative;
            z-index: 1;

            &.left,
            &.leftAlone,
            &.right,
            &.rightAlone,
            &.center {

                //@include mq-from(xs) {
                    float: none;
                    margin: {
                        left: 0;
                        right: 0;
                    }
                //}
            }
        }

        > embed,
        > iframe,
        > object,
        > video {
            width: 100%;
            max-width: 100%;
        }
    }

    img,
    .captionImage,
    .embed {

        &.left {

            //@include mq-from(xs) {
                float: left;
                margin-right: rem(16px);
                //margin-right: $cmscontent-margin-unit;
            //}
        }

        &.leftAlone {
            margin-right: auto;

            //@include mq-from(xs) {
            //    margin-right: auto;
            //}
        }

        &.right {

            //@include mq-from(xs) {
                float: right;
                margin-left: rem(16px);
                //margin-left: $cmscontent-margin-unit;
            //}
        }

        &.rightAlone {

            margin-left: auto;

            //@include mq-from(xs) {
            //    margin-left: auto;
            //}
        }

        &.center {

            //@include mq-from(xs) {
                margin: {
                    left: auto;
                    right: auto;
                }
            //}
        }
    }

    table {

        th,
        td {

            &.text-right {
                text-align: right;
            }

            &.text-center {
                text-align: center;
            }

            &.text-left {

            }
        }
    }

}
