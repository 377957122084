.nsw-side-nav {

    > ul > li.has-children + li {
        border-top: solid 1px var(--nsw-grey-03);
    }

    li {

        a {
            position: relative;
            padding: {
                top: rem(12px);
                bottom: rem(12px);
            }
        }
    }

    li li {

        a {
            position: relative;
            padding: {
                left: calc(#{rem(24px)} + #{rem(19px)});
            }

            &:before {
                @include material-icons;
                content: 'subdirectory_arrow_right';
                color: var(--nsw-grey-03);
                position: absolute;
                top: rem(11px);
                left: rem(22px);
            }
        }
    }

    li li li {

        &:first-child {
            border-top: solid 1px var(--nsw-grey-03);
        }

        a {
            padding-left: calc(#{rem(32px)} + #{rem(19px)});

            &:before {
                left: rem(30px);
            }
        }
    }

    li li li li {

        a {
            padding-left: calc(#{rem(48px)} + #{rem(19px)});

            &:before {
                left: rem(46px);
            }
        }
    }

    a.current {
        border-left: solid 2px var(--nsw-brand-accent);
        font-weight: var(--nsw-font-bold);
    }

    &__header {

        a {
            color: var(--nsw-brand-dark);
        }
    }

    &.-size-sm {

        > ul {
            font-size: var(--nsw-font-size-xs);
            line-height: var(--nsw-font-height-xs);
        }
    }
}
