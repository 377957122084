.SurveyOptionsetField__field:focus-within {
    outline: solid 3px var(--nsw-focus);
    outline-offset: 3px;
}

.SurveyOptionsetField__options {
    display: grid;
    grid-gap: 6px;
    grid-template-columns: repeat(6, 1fr);
}

.SurveyOptionsetField__label {
    padding: 12px 6px;
    border: 1px solid var(--nsw-grey-01);
    text-align: center;
    border-radius: var(--nsw-border-radius);
    cursor: pointer;
}

.SurveyOptionsetField__input:checked + .SurveyOptionsetField__label,
.SurveyOptionsetField__label:focus {
    background-color: var(--nsw-brand-dark);
    color: var(--nsw-text-light);
    font-weight: bold;
    border-color: var(--nsw-brand-dark);
}

.SurveyOptionsetField__input:checked + .SurveyOptionsetField__label:hover,
.SurveyOptionsetField__label:hover {
    background-image: linear-gradient(var(--nsw-text-hover), var(--nsw-text-hover));
}

.SurveyOptionsetField__help {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-style: italic;
}
