@media print {

    @page {
        size: A4;
        margin-top: 20mm;
    }

    a[href]::after {
        word-wrap: break-word !important;
    }

    .nsw-breadcrumbs,
    .nsw-in-page-nav {

        a,
        a:visited {
            text-decoration: none !important;
        }

        a[href]::after {
            content: none !important;
        }
    }

    .nsw-skip,
    .nsw-main-nav,
    .nsw-global-alert,
    .nsw-back-to-top,
    #BetterNavigator,
    .highcharts-exporting-group,
    .pcat-actions {
        display: none !important;
    }

    .nsw-layout {
        display: block !important;

        &__main {
            width: 100% !important;
            max-width: none !important;
        }

        &__sidebar {
            display: none !important;
        }
    }

    .nsw-hero-banner__content {
        padding-bottom: 1rem !important;
        min-height: auto !important;
        padding-top: 2rem !important;
    }

    .nsw-hero-banner__links {
        padding-top: 3rem;
    }

    .nsw-card,
    .nsw-callout,
    .element-feature,
    li:has(> .nsw-link-list__body) {
        break-inside: avoid;
    }

    .nsw-link {
        word-wrap: break-word;
        max-width: 100%;
    }

    .nsw-accordion {

        &__toggle {
            display: none !important;
        }

        &__title > button,
        &__content {
            padding-left: 0;
            padding-right: 0;
        }
    }

    .s-cmsContent {
        max-width: none !important;

        blockquote {
            border-top: none;
            border-right: none;
            border-bottom: none;
        }

        table {
            font-size: 90% !important;
            width: 100% !important;
            max-width: 100% !important;
            table-layout: fixed;
        }
    }

    .highcharts-data-table {

        table {
            font-size: 90% !important;
            width: 100% !important;
            max-width: 100% !important;
            table-layout: fixed;
        }
    }

    .ElementEmbed {

        .nsw-media {
            display: none !important;
        }

        .nsw-callout {
            display: block !important;
        }
    }

    html {
        font-size: 90%;

        &.pdfexport {
            //font-size: 85% !important;
            -webkit-print-color-adjust: exact;
            padding: {
                left: rem(25);
                right: rem(25);
                top: 0;
                bottom: 0;
            }

            a[href]::after {
                content: none !important;
            }

            &.class-pcatpage.action-results {
                font-size: 85% !important;

                .nsw-breadcrumbs {
                    display: none !important;
                }

                .nsw-hero-banner {
                    margin-bottom: -1rem !important;
                }

                .nsw-hero-banner__content {
                    max-width: 100% !important;
                    width: 100% !important;
                    padding-top: 1rem !important;

                    .nsw-intro {
                        display: none !important;
                    }
                }

                .pcat-results-code .nsw-card__content {
                    background-color: var(--nsw-brand-light) !important;
                }

                .pcat-results-help {
                    margin-bottom: 0 !important;

                    .pcat-results-help-text {
                        font-size: var(--nsw-font-size-xs-desktop) !important;
                        line-height: var(--nsw-line-height-xs-desktop) !important;

                        a[href]::after {
                            content: '' !important;
                        }
                    }
                }
            }
        }

        &:not(.pdfexport) {

            .nsw-card__image {
                display: none !important;

                + .nsw-card__content {
                    border-top-width: 1px !important;
                    border-top-color: var(--nsw-grey-03) !important;
                    border-top-left-radius: var(--nsw-border-radius) !important;
                    border-top-right-radius: var(--nsw-border-radius) !important;
                }
            }

            .element-feature .nsw-media {
                display: none !important;
            }

            .nsw-card--title a[href]::after,
            .nsw-list-item li a[href]::after,
            .nsw-link-list--content li a[href]::after {
                content: ' (' attr(href) ')' !important;
                position: static !important;
                display: block !important;
            }
        }

        .u-print-hide {
            display: none !important;
        }

        .u-print-break-avoid {
            break-inside: avoid !important;
        }

        .u-print-break-after {
            break-after: page !important;
        }

        .u-print-break-before {
            break-before: page !important;
        }
    }
}
