.CapabilityFieldGroup legend {
    padding: 0;
    display: table;
}

fieldset.CapabilityFieldGroup {
    border: 0;
    padding: 0.01em 0 0 0;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 0;
    min-width: 0;
}

fieldset.CapabilityFieldGroup:not(.nsw-form__group) {
    margin-top: 0;
}

body:not(:-moz-handler-blocked) fieldset.CapabilityFieldGroup {
    display: table-cell;
}

.CapabilityFieldGroup__fields {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 12px;
}
