.CapabilityGroupsField__hd {margin-bottom: 6px;}
.CapabilityGroupsField__title {
    display: grid;
    align-items: center;
    border-bottom: 2px solid var(--nsw-palette-grey-03);
    padding: 16px 24px !important;
}
.CapabilityGroupsField__title--group {height: 100%;}
.CapabilityGroupsField__fieldset {margin-bottom: 6px;}
.CapabilityGroupsField__legend {width: auto; margin-left: auto; margin-right: auto;}
.CapabilityGroupsField__legend em {display: block;}
.CapabilityGroupsField__group-tile {padding: 16px 24px;}
.CapabilityGroupsField__logo {fill: currentColor !important; max-width: 100%; width: 50px; height: auto;}
.CapabilityGroupsField__levels {padding: 24px; border-left: 6px solid currentColor;}
@media (max-width: 767px) {
    .CapabilityGroupsField__group {position: sticky; top: 0;}
    .CapabilityGroupsField__group-tile {padding: 9px 24px; display: flex; align-items: center; gap: 18px;}
    .CapabilityGroupsField__legend {margin-left: 0; text-align: left;}
}
@media (min-width: 768px) {
    :root {--cadts-hd-height: 0;}
    .CapabilityGroupsField__hd {position: relative;}
    .CapabilityGroupsField__groupTitle {border-right: 6px solid white;}
    .CapabilityGroupsField__group {border-right: 6px solid white;}
    .CapabilityGroupsField__group-tile {padding-top: 24px; padding-bottom: 24px;}
    .CapabilityGroupsField.is-sticky .CapabilityGroupsField__group-tile {padding-top: 24px; padding-bottom: 24px; position: sticky; top: var(--cadts-hd-height);}
    .CapabilityGroupsField__logo {width: 70px;}
    .CapabilityGroupsField__levels {border-left: 0;}
}
.-type-capabilitylevelsdropdown .CapabilityGroupsField__icon-selected {display: none;}
.-type-capabilitylevelsdropdown:has(select option:not(option[value=""]):checked) .nsw-form__label {display: flex;}
.-type-capabilitylevelsdropdown:has(select option:not(option[value=""]):checked) .CapabilityGroupsField__icon-selected {color: var(--nsw-palette-yellow-02); order: 1; margin-left: 4px; margin-right: 4px; margin-top: -2px; display: block;}
