$font-family-sans-serif: inherit !default;

.ElementHTML {

  .highchart__gauge-chart {
    display: flex;
    align-items: center;
    font-family: $font-family-sans-serif;
    font-weight: normal;
  }

  .chart-large-number {
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: 1;
    line-height: 0;
    font-family: $font-family-sans-serif;
    transform: translate(-50%, -50%);
    width: 100%;
    text-align: center;
  }

  .chart-large-number__number {
    font-size: 100px;
    line-height: 70px;
  }

  .chart-large-number__percentage {
    font-size: 50px;
    position: relative;
    bottom: 20px;
  }

  .highcharts-menu hr {
    margin: 0.4em 0;
  }

  .highcharts-tooltip > span {
    background-color: white;
    padding: 0.6666666667em;
  }

  .highcharts-tooltip br + span[style^='color:#'] {
    display: none;
  }
}
