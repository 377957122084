.highcharts-data-table,
.highcharts-data-table > table {

    &:focus-visible {
        outline: none;
    }
}

.highcharts-data-table {
    padding-top: rem(24px);
}

.highcharts-table-caption {
    font-style: italic;
}

.highcharts-menu {
    padding: 0 !important;

    &-item {
        margin: 0;
        padding: rem(9px) rem(12px) !important;
    }

    hr {
        margin: rem(3px) 0;
        background-color: var(--nsw-grey-03);
    }
}

.highcharts-title {

    sup, sub {
        font-size: 0.65em;
    }
}
