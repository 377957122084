/// Provides an easy way to include a clearfix for containing floats.
///
/// @link http://cssmojo.com/latest_new_clearfix_so_far/
///
/// @example scss - Usage
///   .element {
///     @include clearfix;
///   }
///
/// @example css - CSS Output
///   .element::after {
///     clear: both;
///     content: "";
///     display: table;
///   }


@mixin clearfix {
  &::after {
    clear: both;
    content: "";
    display: table;
  }
}